import React, { memo } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import { Navbar, Footer, SEO } from "../../components";

import { Masonry } from "../../components/ui";

const Atvaltoztato = () => {
  const images = useStaticQuery(graphql`
    query atvaltoztatoPortfolioQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "gallery" }
          relativeDirectory: { eq: "atvaltoztato" }
        }
        sort: { fields: childImageSharp___fluid___originalName, order: ASC }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 3200) {
              src
              sizes
              aspectRatio
              presentationHeight
              presentationWidth
              originalName
            }
          }
        }
      }
    }
  `).allFile.nodes.map((item) => ({
    src: item.childImageSharp.fluid.src,
    sizes: item.childImageSharp.fluid.sizes,
    width: item.childImageSharp.fluid.presentationWidth,
    height: item.childImageSharp.fluid.presentationHeight,
  }));

  return (
    <div>
      <SEO
        title="Merkl Kinga - Önbizalomnövelő fotózás"
        description="Nőkről nőknek. Szeretnéd meglepni a barátnődet, feleségedet egy ilyen fotózással? Tökéletes választás akár ajándékutalvány formájában. Ez a nap ugyanis csak róla fog szólni, mert megérdemli. Fodrásztól sminkeshez, sminkestől stúdióba. 
Ezt a fotózást azoknak a nőknek találtam ki, akik szeretnék a lelküket, testüket kicsit kényeztetni. Ha egy kis extra önbizalomra van szükséged gyere és bízd ránk magadat. 
Egy profi csapat lesz körülötted. Nem átváltoztatunk. Szeretnénk megmutatni, hogy milyen vagy valójában. A valóság legjobb formájában. 
Felébreszteni a benned lévő nőt, a régen elveszettnek hitt önbizalmat és bebizonyítani, hogy igenis jó nő vagy. Én pedig képek formájában megörökítem ezt a napot, 
hogy később a képek nézegetésével újra és újra emlékeztesselek, hogy ki is vagy valójában."
      />
      <Navbar />
      <div className="min-h-256 flex flex-col items-center justify-center bg-0-500">
        <h1 className="font-bold text-4xl text-white">
          Önbizalomnövelő fotózás
        </h1>
        <Link
          to="/portfolio"
          title="További kategóriák"
          className="text-white mt-8 hover:underline"
        >
          További kategóriák
        </Link>
        <Link
          to="/#csomagokesarak"
          title="Árak"
          className="text-white mt-4 hover:underline"
        >
          Árak
        </Link>
      </div>
      <Masonry images={images} />
      <Footer />
    </div>
  );
};

export default memo(Atvaltoztato);
